import React from 'react'
import Layout from '../components/layout'
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import { Icon } from 'leaflet'

function LocationMarker({ position, color }) {
    const map = useMapEvents({
        click() {
            map.setView(position, 16)
        }
    })

    const icon = new Icon({
        iconUrl: encodeURI(
            'data:image/svg+xml,' +
                `<svg fill="${color}" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>`
        ).replace('#', '%23'),
        iconSize: [48, 48],
        iconAnchor: [24, 48]
    })

    return <Marker position={position} icon={icon}></Marker>
}

class NotFoundPage extends React.Component<GatsbyProps> {
    render() {
        const pageName = 'Contact'
        const position = [44.412787, 26.003667] as [number, number]
        const markerColor = '#ff1722'

        return (
            <Layout location={this.props.location}>
                <main className="container mx-auto p-4">
                    <h1>{pageName}</h1>
                    <p>
                        E-mail:{' '}
                        <a href="mailto:office@tehcom.ro" className="hover:underline">
                            office@tehcom.ro
                        </a>
                    </p>
                    <p>
                        Mobil:{' '}
                        <a href="tel:+40722344455" className="hover:underline">
                            +40 (0)72 234 4455
                        </a>
                    </p>
                    <p>
                        Fix/Fax:{' '}
                        <a href="tel:+40763930311" className="hover:underline">
                            +40 (0)76 393 0311
                        </a>
                    </p>
                    <p>
                        Adresa: Drumul Valea Ursului, Nr. 44, cod postal 061993, sector 6, Bucuresti
                    </p>
                    {typeof window !== 'undefined' && (
                        <MapContainer
                            className="z-0 shadow rounded-sm"
                            fullscreenControl
                            center={position}
                            zoom={16}
                        >
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <LocationMarker position={position} color={markerColor} />
                        </MapContainer>
                    )}
                </main>
            </Layout>
        )
    }
}

export default NotFoundPage
